<template>
  <div class="course innerpage">
    <Header />

    <v-container class="my-6 innerpage-container">
      <v-row>
        <v-col lg="9">
          <div id="quiz">
            <div>
              <h4 class="h4 pb-6">{{ title }}</h4>
              <template v-if="status === 'Intro' && !quizQuestions">
                <div class="mb-12" v-html="quiz.started_html"></div>
                <template v-if="questions.length > 0">
                  <v-select
                    v-if="!isUserBasic"
                    v-model="workerGroupSelected"
                    :items="workerGroupsList"
                    item-text="name"
                    :menu-props="{ maxHeight: '400' }"
                    label="Select a Worker Group"
                    return-object
                    class="ml-sm-3 mt-6"
                    style="max-width: 320px"
                  ></v-select>
                  <v-btn
                    color="primary"
                    class="mt-6"
                    @click="startQuiz"
                    rounded
                  >
                    Start
                  </v-btn>
                </template>
              </template>
              <div v-else-if="quizResults && !loaderQuiz" id="printSummary">
                <div
                  class="d-flex align-center justify-space-between progress rounded-t pa-5"
                >
                  <div v-html="quiz.completed_heading"></div>
                </div>

                <div class="pa-5">
                  <!-- If quiz summary -->
                  <div
                    class="d-md-flex mb-5 align-center justify-space-between"
                  >
                    <!-- If the final quiz, show the download button -->
                    <template v-if="finalQuiz === true">
                      <div>
                        <div>
                          <h3 class="text-h5">
                            Results:
                            <span
                              :class="`${finalRiskAssessment.alert_type}--text`"
                            >
                              <strong>{{ finalRiskLevel }}</strong>
                            </span>
                          </h3>
                        </div>
                      </div>
                      <div>
                        <v-btn
                          @click="gotoDashboard"
                          color="secondary"
                          rounded
                          dark
                          class="ml-auto mr-3 my-1"
                        >
                          <v-icon left>mdi-open-in-new</v-icon>
                          Go to Dashboard
                        </v-btn>
                        <!-- <v-btn text small rounded class="ml-auto my-1" @click="print">Print Summary</v-btn> -->
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        <h3 class="text-h5">
                          Risk Assessment: Low
                        </h3>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div v-else-if="quizQuestions && !loaderQuiz">
                <v-card class="mx-auto" elevation="8">
                  <div>
                    <div
                      class="d-flex align-center justify-space-between progress rounded-t pa-5 mt-6"
                    >
                      <div class="progress__percent">
                        Progress: {{ quizProgressPercentage }}%
                      </div>
                      <div class="progress__bar">
                        <v-progress-linear
                          :value="quizProgressPercentage"
                          rounded
                          dark
                          color="white"
                        ></v-progress-linear>
                      </div>
                      <div>
                        {{ currentQuestionIndex + 1 }} of {{ quizLength }}
                      </div>
                    </div>
                    <template>
                      <div class="question pa-5">
                        <v-form ref="form" v-model="valid" v-on:submit.prevent>
                          <div>
                            <div>
                              <h3 class="text-h5 py-6">
                                {{ currentQuestion.content }}
                              </h3>

                              <div v-if="currentQuestion.type === 'text'">
                                <v-text-field
                                  v-model="answers[currentQuestion.id]"
                                  :rules="[v => !!v || 'Answer is required']"
                                  label="Please enter"
                                  filled
                                  :required="currentQuestion.required"
                                ></v-text-field>
                              </div>

                              <!-- if question type is radio buttons -->
                              <div v-if="currentQuestion.type === 'radio'">
                                <v-radio-group
                                  v-model="answers[currentQuestion.id]"
                                  column
                                  :rules="[v => !!v || 'Answer is required']"
                                >
                                  <div
                                    v-for="item in sortedResponses"
                                    :key="item.id"
                                  >
                                    <div class="mb-2 d-flex align-center">
                                      <v-radio
                                        :value="item.id"
                                        color="secondary"
                                        :required="currentQuestion.required"
                                      >
                                        <template v-slot:label>
                                          <span v-html="item.content"></span>
                                        </template>
                                      </v-radio>
                                    </div>
                                  </div>
                                </v-radio-group>
                              </div>

                              <!-- if question type is checkboxes -->
                              <div v-if="currentQuestion.type === 'checkbox'">
                                <div
                                  v-for="item in sortedResponses"
                                  :key="item.id"
                                >
                                  <div class="d-flex align-center">
                                    <v-checkbox
                                      ref="chex"
                                      v-model="answers[currentQuestion.id]"
                                      :multiple="true"
                                      :value="item.id"
                                      :rules="checkboxRules"
                                      @change="
                                        validateAnswer(
                                          item,
                                          currentQuestion.id,
                                          currentQuestion.responses
                                        )
                                      "
                                      color="secondary"
                                      hide-details
                                      :required="currentQuestion.required"
                                    >
                                      <template v-slot:label>
                                        <span v-html="item.content"></span>
                                      </template>
                                    </v-checkbox>

                                    <v-tooltip v-if="item.help_text" bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          color="primary"
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                          class="pt-4 pl-2"
                                        >
                                          mdi-alert-circle
                                        </v-icon>
                                      </template>
                                      <span>{{ item.help_text }}</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- Quiz Controls -->
                            <div class="d-flex pa-5 pt-12">
                              <v-btn
                                color="secondary"
                                rounded
                                v-if="currentQuestionIndex > 0"
                                v-on:click="previousHandler"
                                >Previous
                              </v-btn>
                              <v-btn
                                color="secondary"
                                rounded
                                v-on:click="nextHandler"
                                class="ml-auto"
                                >Next
                              </v-btn>
                            </div>
                          </div>
                        </v-form>
                      </div>
                    </template>
                  </div>
                </v-card>
              </div>
              <div v-else-if="questions.length === 0" class="mt-5">
                <p>There are no questions.</p>
              </div>
              <div v-if="status !== 'Intro' && loaderQuiz" class="d-flex align-center justify-center pa-10 ma-auto">
                <v-progress-circular
                  indeterminate
                  color="blue-grey"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showModal" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Are you sure to leave?
        </v-card-title>
        <v-card-text
          >If you leave, all your progress will not be saved.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="leaveSite()">
            Yes
          </v-btn>
          <v-btn color="red darken-1" text @click="showModal = false">
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//import { Role } from "@/helpers/roles";
import _ from "lodash";
import Header from "@/components/Header.vue";
//import Question from "@/components/Question.vue";
//import UserService from "@/services/user.service";
import TeamWorkergroupService from "@/services/teamworkergroup.service";
import ReportService from "@/services/report.service";
import CoursesService from "../services/courses.service";
import ResultsService from "@/services/results.service";
import authServices from "@/services/auth.service";
import { Role } from "@/helpers/roles";

export default {
  name: "QuizSingle",
  components: {
    Header
    //Question
  },
  data() {
    return {
      quiz: {},
      finalRiskLevel: "",
      questionsCount: 0,
      isLoaded: false,
      quizzes: [],
      quizResults: false,
      submittedReport: [],
      course: {},
      scores: {},
      totalScore: 0,
      introStage: false,
      questionStage: false,
      resultsStage: false,
      title: "",
      questions: [],
      currentQuestionIndex: 0,
      answers: [],
      newAnswers: [],
      correct: 0,
      perc: null,
      status: "Intro",
      loadPreviousData: false,
      loadQuiz: false,
      //quizLength: 0,
      nextPage: null,
      showModal: false,
      //workerGroupSelected: null,
      workerGroupsList: [],
      valid: true,
      answersBackup: null,
      quizQuestions: false,
      finalRiskAssessmentScore: 0,
      finalRiskAssessment: [],
      scorePercentage: 0,
      toDashboard: false,
      loaderQuiz: false
    };
  },
  computed: {
    currentQuestion() {
      return this.questionList[this.currentQuestionIndex];
    },
    quizLength: function() {
      return this.questionList.length;
    },
    quizProgressPercentage: function() {
      return Math.round((this.currentQuestionIndex * 100) / this.quizLength);
    },

    questionList() {
      const sortedQuestions = this.quiz.questions.slice();
      return _.orderBy(sortedQuestions, "sort_order", "asc");
    },
    initialquestionList() {
      const sortedQuestions = this.quiz.questions.slice();
      return _.orderBy(sortedQuestions, "sort_order", "asc");
    },
    sortedResponses() {
      // sort by sort_order
      const sortedResponses = this.currentQuestion.responses.slice();
      return _.orderBy(sortedResponses, "sort_order", "asc");
    },

    currentUser: function() {
      return this.$store.state.auth.user;
    },
    wgtypeId() {
      return this.currentUser.user.workergroups
        ? this.currentUser.user.workergroups[0].wgtype_id
        : null;
    },
    // checkboxRules: function() {
    //   return [
    //     (this.answers[this.currentQuestion.id] &&
    //       this.answers[this.currentQuestion.id].length > 0) ||
    //       "You must select at least one"
    //   ];
    // },
    checkboxRules: function() {
      const answerIds = this.answers[this.currentQuestion.id];
      const answerIdsArray = Array.isArray(answerIds) ? answerIds : [answerIds];

      let answers = this.currentQuestion.responses.filter(item => {
        return answerIdsArray.includes(item.id);
      });

      return [answers.length > 0 || "You must select at least one"];
    },

    myQuizzes: function() {
      if (this.$route.query.cr) {
        return this.$store.state.quizStatus.myQuizzes.slice(0).reverse();
      } else {
        return this.$store.state.quizStatus.myQuizzes;
      }
    },

    //find the first quiz in the course
    firstQuiz: function() {
      //display vuex store of quizStatus completed in an array
      const currentQuizId = this.quiz.id;
      const firstQuizId = this.quizzes[0].id;

      //current quiz matches last quiz in course
      let first = false;

      if (currentQuizId == firstQuizId) {
        return (first = true);
      } else {
        return first;
      }
    },

    //find the last quiz in the course
    finalQuiz: function() {
      //display vuex store of quizStatus completed in an array
      // const currentQuizId = this.quiz.id;
      // const lastQuizId = this.quizzes[this.quizzes.length - 1].id;

      // //current quiz matches last quiz in course
      // let final = false;

      // if (currentQuizId == lastQuizId) {
      //   return (final = true);
      // } else {
      //   return final;
      // }
      return true;
    },

    storedReport: function() {
      //display vuex store of quizStatus completed in an array
      const currentQuizId = this.quiz.id;
      const firstQuizId = this.quizzes[0];

      //current quiz matches last quiz in course
      let storedReport = this.$store.state.quizStatus.reportId;

      if (currentQuizId == firstQuizId) {
        return (storedReport = null);
      } else {
        return storedReport;
      }
    },

    isUserBasic() {
      let userBasic = false;
      this.currentUser.user.roles.forEach(item => {
        if (item.role_id === Role.UserBasic) {
          userBasic = true;
        }
      });
      return userBasic;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
    }
    this.initialize();
  },

  beforeRouteLeave(to, from, next) {
    if (to.name === "login") {
      next();
    } else if (this.toDashboard === true) {
      next();
    } else if (!this.onLiveEditing && !this.quizResults) {
      this.preventNav(next);
    } else {
      next();
    }
  },
  methods: {
    recoverDataBackup() {
      this.loadPreviousData = false;
      this.answersBackup = true;
      this.loadQuiz = true;
    },

    clearBackup() {
      window.localStorage.removeItem("backupQuiz_" + this.quiz.id);
    },

    saveBackup() {
      let backupQuiz = {
        answers: this.answers,
        newAnswers: this.newAnswers,
        currentQuestionIndex: this.currentQuestionIndex,
        questions: this.questions,
        questionList: this.questionList,
        status: this.status,
        scores: this.scores,
        workerGroupSelected: this.workerGroupSelected,
        firstQuizCompleted: this.firstQuizCompleted,
        reportId: this.reportId
      };
      window.localStorage.setItem(
        "backupQuiz_" + this.quiz.id,
        JSON.stringify(backupQuiz)
      );
    },

    async initialize() {
      this.loaderQuiz = true;
      this.setWorkerGroupsList();

      await this.setQuiz();
      await this.setCourse();
      await this.setQuizzes();
      

      this.reportId = this.storedReport;

      if (this.reportId !== null) {
        const responseReport = await ReportService.getExtendedReport({
          id: this.reportId ? this.reportId : this.$route.query.cr
        });

        this.crData = responseReport;

        if (
          JSON.parse(responseReport.data.report.debug_data).continueReport &&
          JSON.parse(responseReport.data.report.debug_data).continueReport
            .savedBackup
        ) {
          let quizSaved = Object.keys(
            JSON.parse(responseReport.data.report.debug_data).continueReport
              .savedBackup
          );
          let dataBackup = JSON.parse(responseReport.data.report.debug_data)
            .continueReport.savedBackup;
          let indexQuizSaved = quizSaved.findIndex(e => e == this.quiz.id);
          if (indexQuizSaved >= 0) {
            //the quiz is equal at our save
            this.answers = dataBackup[this.quiz.id].answers;
            this.currentQuestionIndex =
              dataBackup[this.quiz.id].currentQuestionIndex;
            this.newAnswers = dataBackup[this.quiz.id].newAnswers;
            this.quizIntro = dataBackup[this.quiz.id].quizIntro;
            this.quizQuestions = dataBackup[this.quiz.id].quizQuestions;
            this.questionList = dataBackup[this.quiz.id].questionList;
            this.quizIsCompleted = false;
            this.workerGroupSelected =
              dataBackup[this.quiz.id].workerGroupSelected;
            this.scores = dataBackup[this.quiz.id].scores;
            this.reportId = dataBackup[this.quiz.id].reportId;
            //if it already has all the answers
            if (
              Object.values(dataBackup[this.quiz.id].answers).length ===
              dataBackup[this.quiz.id].questionList.length
            ) {
              this.quizCompleted();
            }
          }
        }
      }

      this.getQuizForCurrentSection();

      if (this.quizzes.length) {
        this.$store.commit("changeFirstQuizStatus", true);
      }

      if (this.answersBackup) {
        let savedBackup = JSON.parse(
          window.localStorage.getItem("backupQuiz_" + this.quiz.id)
        );
        this.answers = savedBackup.answers;
        this.currentQuestionIndex = savedBackup.currentQuestionIndex;
        this.newAnswers = savedBackup.newAnswers;
        this.quizIntro = savedBackup.quizIntro;
        this.quizQuestions = savedBackup.quizQuestions;
        this.questionList = savedBackup.questionList;
        this.quizIsCompleted = false;
        this.workerGroupSelected = savedBackup.workerGroupSelected;
        this.scores = savedBackup.scores;
        this.reportId = savedBackup.reportId;
      }

      this.updateQuestionList();
      this.loaderQuiz = false;

      // if (
      //   window.localStorage.getItem("backupQuiz_" + this.quiz.id) !== null &&
      //   Object.keys(
      //     JSON.parse(window.localStorage.getItem("backupQuiz_" + this.quiz.id))
      //   ).length > 1
      // ) {
      //   this.loadPreviousData = true;
      // } else {
      //   this.loadPreviousData = false;
      //   this.loadQuiz = true;
      // }

      // if (this.loadPreviousData) {
      //   let savedBackup = JSON.parse(
      //     window.localStorage.getItem("backupQuiz_" + this.quiz.id)
      //   );
      //   console.log("here", savedBackup);
      //   this.answers = savedBackup.answers;
      //   this.currentQuestionIndex = savedBackup.currentQuestionIndex;
      //   this.questions = savedBackup.questions;
      //   this.status = savedBackup.status;
      //   this.newAnswers = savedBackup.newAnswers;
      //   this.questionList = savedBackup.questionList;
      //   this.workerGroupSelected = savedBackup.workerGroupSelected;
      //   this.scores = savedBackup.scores;
      //   this.reportId = savedBackup.reportId;
      // }
    },

    async getQuizForCurrentSection() {
      //this.loadQuiz = false;

      let quizLoaded = false;

      if (this.quiz.id) {
        if (
          !this.reportId &&
          window.localStorage.getItem("backupQuiz_" + this.quiz.id) !== null &&
          Object.keys(
            JSON.parse(
              window.localStorage.getItem("backupQuiz_" + this.quiz.id)
            )
          ).length > 1
        ) {
          this.answersBackup = true;
        }

        //check if the has already completed the firs questionare

        const quizData = await CoursesService.getQuiz(this.quiz.id);
        this.quiz = quizData.data.data;
        this.questionsCount = this.quiz.questions.length;

        if (this.reportId) {
          const responseReport = await ReportService.getExtendedReport({
            id: this.reportId
          });
          if (
            (JSON.parse(responseReport.data.report.debug_data).continueReport &&
              JSON.parse(responseReport.data.report.debug_data).continueReport
                .savedBackup) ||
            JSON.parse(responseReport.data.report.debug_data).prevCompletedQuiz
          ) {
            // let dataBackup = JSON.parse(responseReport.data.report.debug_data).continueReport.dataBackup;
            // var findQuizBackup = dataBackup.data.findIndex(e => e.quizId === this.quiz.id)
            // if (findQuizBackup >= 0) {//if we have the previous quiz completed, we can continue
            this.crQuizReady = true;
            // }
          }
        }

        quizLoaded = true;
      }
      return (this.isQuizLoaded = quizLoaded);
    },

    async setQuizzes() {
      CoursesService.getCourse(this.course.id).then(
        response => {
          this.quizzes = response.data.data.custom_quizzes;
        },
        error => {
          this.apiError =
            (error.response && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    async setQuiz() {
      const quizData = await CoursesService.getQuiz(this.$route.params.id);
      this.quiz = quizData.data.data;
      this.title = this.quiz.name;

      this.questions = this.quiz.questions;
      this.introStage = true;
      this.status = "Intro";
      this.quizLength = this.questions.length;
    },

    async setCourse() {
      const response = await CoursesService.getCourse(
        this.quiz.custom_course_id
      );
      this.course = response.data.data;
    },

    async startQuiz() {
      this.loaderQuiz = true;
      if (this.firstQuiz) {
        //create the report
        const reportData = {
          user_id: this.currentUser.user.id,
          organization_id: this.currentUser.user.organizations.id,
          organization_name: this.currentUser.user.organizations.company,
          quiz_id: this.quiz.id,
          quiz_name: this.quiz.name,
          course_id: this.course.id,
          location_id: this.currentUser.user.locations.id,
          location_name: this.currentUser.user.locations.name,
          workergroup_id: this.workerGroupSelected.id,
          workergroup_name: this.workerGroupSelected.name,
          wgtype_id: this.wgtypeId,
          debug_data: JSON.stringify({ x: 1, y: 2 })
        };

        const response = await ReportService.createReport(reportData);
        this.reportId = response.data.report_id;

        const initialStatus = {
          status: "Incomplete"
        };
        await ReportService.update({
          id: this.reportId,
          data: initialStatus
        });

        localStorage.setItem("reportOnCourseID", JSON.stringify(this.reportId));

        this.status = "Incomplete";
        this.introStage = false;
        this.questionStage = true;
      }
      this.quizIntro = false;
      this.quizQuestions = true;
      this.loaderQuiz = false;
    },
    updateQuestionList() {
      this.questionList = this.initialQuestionList.filter(question => {
        if (question.conditions.length > 0) {
          // If the question is conditional, see if we can find it's parent questions in existing answers
          // Answers from current quiz

          //i need to loop through each condition and get the condition_question_id - check if thats been answered in this.answers

          const conditionsMet = question.conditions.map(condition => {
            const answer = this.answers[condition.condition_question_id];
            const requiredAnswer = condition.condition_response_id;

            // Find conditional answers in the current quiz
            if (Array.isArray(answer)) {
              // Handle Array values, e.g. checkboxes / radio buttons
              if (answer.includes(requiredAnswer)) {
                return true;
              }
            } else {
              // Handle String values
              if (answer == requiredAnswer) {
                return true;
              }
            }

            if (
              this.$route.query.cr &&
              JSON.parse(this.crData.data.report.debug_data).prevCompletedQuiz
            ) {
              const savedCompletedQuizzesPrev = JSON.parse(
                this.crData.data.report.debug_data
              ).prevCompletedQuiz;
              if (!this.firstQuiz) {
                if (!savedCompletedQuizzesPrev[condition.condition_quiz_id]) {
                  return false;
                }

                const storedAnswer = savedCompletedQuizzesPrev[
                  condition.condition_quiz_id
                ].newAnswers.filter(
                  item => item.question_id === condition.condition_question_id
                )[0];

                if (storedAnswer) {
                  const storedResponses = storedAnswer.responses.filter(
                    response => response.id === requiredAnswer
                  );

                  // Find conditional answers from already completed quizzes stored in vuex
                  if (savedCompletedQuizzesPrev[condition.condition_quiz_id]) {
                    if (storedResponses.length > 0) {
                      return true;
                    }
                  }
                } else {
                  return false;
                }
              }
            } else if (
              this.$route.query.cr &&
              JSON.parse(this.crData.data.report.debug_data).continueReport &&
              JSON.parse(this.crData.data.report.debug_data).continueReport
                .savedBackup
            ) {
              const savedCompletedQuizzesCont = JSON.parse(
                this.crData.data.report.debug_data
              ).continueReport.savedBackup;
              if (!this.firstQuiz) {
                if (!savedCompletedQuizzesCont[condition.condition_quiz_id]) {
                  return false;
                }
                const storedAnswer = savedCompletedQuizzesCont[
                  condition.condition_quiz_id
                ].newAnswers.filter(
                  item => item.question_id === condition.condition_question_id
                )[0];

                if (storedAnswer) {
                  const storedResponses = storedAnswer.responses.filter(
                    response => response.id === requiredAnswer
                  );

                  // Find conditional answers from already completed quizzes stored in vuex
                  if (savedCompletedQuizzesCont[condition.condition_quiz_id]) {
                    if (storedResponses.length > 0) {
                      return true;
                    }
                  }
                } else {
                  return false;
                }
              }
            } else {
              const localCompletedQuizzes = this.$store.state.quizStatus
                .completed;

              if (!this.firstQuiz) {
                if (!localCompletedQuizzes[condition.condition_quiz_id]) {
                  return false;
                }

                const storedAnswer = localCompletedQuizzes[
                  condition.condition_quiz_id
                ].filter(
                  item => item.question_id === condition.condition_question_id
                )[0];

                if (storedAnswer) {
                  const storedResponses = storedAnswer.responses.filter(
                    response => response.id === requiredAnswer
                  );

                  // Find conditional answers from already completed quizzes stored in vuex
                  if (localCompletedQuizzes[condition.condition_quiz_id]) {
                    if (storedResponses.length > 0) {
                      return true;
                    }
                  }
                } else {
                  return false;
                }
              }
            }

            return false;
          });

          if (conditionsMet.includes(true)) {
            return question;
          }
        } else {
          return question;
        }
      });
    },
    async setWorkerGroupsList() {
      const userWorkergroup = await TeamWorkergroupService.get({
        user_id: this.$store.state.auth.user.user.id
      });
      this.workerGroupsList = userWorkergroup.data.slice();

      if (this.workerGroupsList.length == 1) {
        this.workerGroupSelected = this.workerGroupsList[0];
      }
    },
    nextHandler() {
      //const currentQuestionId = this.currentQuestion.id;

      //this.answers.push({[currentQuestionId]: e.answer})

      //this.answers[this.currentQuestion.id] = e.answer;

      //Keep Alive on every next question
      this.$store.commit(
        "updateKeepAliveTimer",
        this.$store.state.keepAliveTimerDefault
      );
      authServices.keepAlive();

      const isValid = this.$refs.form.validate();

      if (isValid) {
        //Update the score
        const currentQuestion = this.currentQuestion;

        let ansBlueprint = {
          quiz_id: this.quiz.id,
          question_id: currentQuestion.id,
          test: "hello",
          responses: [],
          ...currentQuestion
        };
        // clean up question
        delete ansBlueprint.id;

        const questionType = this.questions[this.currentQuestionIndex].type;
        let answers = [];
        const hasScore =
          questionType === "checkbox" || questionType === "radio";

        if (hasScore) {
          const answerIds = this.answers[this.currentQuestion.id];
          const answerIdsArray = Array.isArray(answerIds)
            ? answerIds
            : [answerIds];

          answers = currentQuestion.responses.filter(item => {
            return answerIdsArray.includes(item.id);
          });

          const score = answers.reduce((acc, item) => {
            return acc + item.risk_score;
          }, 0);

          this.scores[this.currentQuestion.id] = score;
        }

        if (ansBlueprint.type === "text" || ansBlueprint.type === "textarea") {
          let textAnswer = {
            type: ansBlueprint.type,
            response: _.get(this.answers, ansBlueprint.question_id)
          };

          ansBlueprint.responses = [];
          ansBlueprint.responses.push(textAnswer);
        } else {
          ansBlueprint.responses = answers.slice();
          // rename `content` key to `response`
          ansBlueprint.responses.forEach(res => {
            res.response = res.content;
            // delete res.content;
          });
        }

        this.newAnswers.push(ansBlueprint);

        var infoReport = {
          continueReport: {
            quiz_continue: this.course.id,
            dataBackup: {
              data: []
            }
          }
        };

        infoReport.continueReport.dataBackup.data.push({
          answers: this.answers,
          currentQuestionIndex: this.currentQuestionIndex,
          newAnswers: this.newAnswers,
          quizIntro: this.quizIntro,
          quizId: this.quiz.id,
          quizQuestions: this.quizQuestions,
          questionList: this.questionList,
          workerGroupSelected: this.workerGroupSelected,
          firstQuizCompleted: this.firstQuizCompleted,
          scores: this.scores,
          reportId: this.reportId ? this.reportId : this.$route.query.cr
        });

        if (
          this.crData &&
          JSON.parse(this.crData.data.report.debug_data).prevCompletedQuiz
        ) {
          infoReport.continueReport.savedBackup = JSON.parse(
            this.crData.data.report.debug_data
          ).prevCompletedQuiz;
        } else if (
          this.crData &&
          JSON.parse(this.crData.data.report.debug_data).continueReport
            .savedBackup
        ) {
          infoReport.continueReport.savedBackup = JSON.parse(
            this.crData.data.report.debug_data
          ).continueReport.savedBackup;
        }

        const continueReportData = {
          debug_data: infoReport
        };

        this.status = "In Progress";

        // Move on to next question
        // Move on to next question
        if (this.currentQuestionIndex + 1 === this.questionList.length) {
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          })
            .then(() => {
              // Quiz finished
              this.clearBackup();
              this.quizCompleted();
              this.updateTotalScore();
            })
            .catch(e => {
              this.loaderQuiz = false;
              console.log(e);
              console.log("error");
            });
        } else {
          ReportService.update({
            id: this.reportId,
            data: continueReportData
          })
            .then(() => {
              this.loaderQuiz = false;
              this.currentQuestionIndex++;
              this.saveBackup();
            })
            .catch(e => {
              this.loaderQuiz = false;
              console.log(e);
              console.log("error");
            });
        }
        this.$refs.form.resetValidation();
      }
      //this.$refs.form.reset();
    },

    validateAnswer(item, questionID, cqResponses) {
      if (item.content == "None") {
        //if a "None" response is selected, we clean all the other responses
        this.answers[questionID].length = 0;
        this.answers[questionID].push(item.id);
      } else {
        let noneIndex = cqResponses.findIndex(e => e.content == "None");
        if (noneIndex >= 0) {
          //if a "None" response exist as a possible response of the question
          if (
            this.answers[questionID].findIndex(
              e => e == cqResponses[noneIndex].id
            ) >= 0
          ) {
            //if a "None" response already selected, we delete it
            this.answers[questionID].splice(
              this.answers[questionID].findIndex(
                e => e == cqResponses[noneIndex].id
              ),
              1
            );
          }
        }
      }
    },

    updateTotalScore() {
      this.totalScore = Object.values(this.scores).reduce((acc, value) => {
        return acc + value;
      }, 0);
    },

    async quizCompleted() {
      this.loaderQuiz = true;

      if (this.$route.query.cr) {
        const responseReport = await ReportService.getExtendedReport({
          id: this.$route.query.cr
        });

        this.quizQuestions = false;
        this.quizResults = true;
        this.updateTotalScore();
        const riskLevel = this.quiz.risk_profiles?.filter(item => {
          return (
            this.totalScore >= item.min_score &&
            this.totalScore < item.max_score
          );
        });
        if (riskLevel.length === 0) {
          const defaultRiskResult = [
            { profile_name: "Unknown", alert_type: "info" }
          ];
          this.riskResult = defaultRiskResult[0];
        } else {
          this.riskResult = riskLevel[0];
        }
        if (
          Object.keys(responseReport.data.quiz).findIndex(
            e => e == this.quiz.id
          ) == -1
        ) {
          const data = {
            report_id: this.reportId ? this.reportId : this.$route.query.cr,
            quiz_id: this.quiz.id,
            results: this.newAnswers
          };
          await ResultsService.submitResult({
            data: data
          });
        }

        var reportStatus = {
          status: "In Progress"
        };

        if (
          JSON.parse(this.crData.data.report.debug_data).continueReport &&
          JSON.parse(this.crData.data.report.debug_data).continueReport
            .savedBackup
        ) {
          this.calcFinalRiskAssessment();
          this.$store.dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          });

          // check if pass or fail
          this.calculateScorePercentage();

          if (this.finalQuiz) {
            reportStatus.status = "completed";
            await ReportService.update({
              id: this.reportId ? this.reportId : this.$route.query.cr,
              data: reportStatus
            });
          } else {
            reportStatus.status = "In Progress";
          }
          //map on saved quizes and check if is in myquizzes
          //if not, then push
          Object.values(
            JSON.parse(this.crData.data.report.debug_data).continueReport
              .savedBackup
          ).forEach(e => {
            let indexQuiz = this.myQuizzes.findIndex(
              i => i.quizId === e.quizId
            );
            if (indexQuiz < 0) {
              //is not there then push
              this.$store.dispatch("quizStatus/complete", {
                id: e.quizId,
                results: e.newAnswers,
                reportId: e.reportId,
                quizName: e.quizName,
                riskResult: e.riskResult,
                riskScore: e.totalScore
              });
            }
          });
        } else if (
          JSON.parse(this.crData.data.report.debug_data).prevCompletedQuiz
        ) {
          const responseReport = await ReportService.getExtendedReport({
            id: this.$route.query.cr
          });
          this.crData = responseReport;
          this.calcFinalRiskAssessment();
          this.$store.dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          });

          // check if pass or fail
          this.calculateScorePercentage();

          if (this.finalQuiz) {
            reportStatus.status = "completed";
            await ReportService.update({
              id: this.reportId ? this.reportId : this.$route.query.cr,
              data: reportStatus
            });
          }
        } else {
          this.$store.dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          });
          reportStatus.status = "In Progress";
          let quizKeyName = this.quiz.id.toString();

          let infoReport = {
            prevCompletedQuiz: {}
          };
          infoReport.prevCompletedQuiz[quizKeyName] = {
            quiz_continue: this.course.id,
            chapter: this.$route.query.chapter,
            page: this.$route.query.page,
            answers: this.answers,
            currentQuestionIndex: this.currentQuestionIndex,
            newAnswers: this.newAnswers,
            quizIntro: this.quizIntro,
            quizId: this.quiz.id,
            quizQuestions: this.quizQuestions,
            questionList: this.questionList,
            totalScore: this.totalScore,
            workerGroupSelected: this.workerGroupSelected,
            firstQuizCompleted: this.firstQuizCompleted,
            scores: this.scores,
            reportId: this.reportId ? this.reportId : this.$route.query.cr,
            quizName: this.quiz.name,
            riskResult: this.riskResult
          };

          const continueReportData = {
            debug_data: infoReport
          };
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          });
        }
      } else {
        //If first quiz, rease a new report id and also save in vuex
        if (this.firstQuiz) {
          this.updateTotalScore();
          const riskLevel = this.quiz.risk_profiles?.filter(item => {
            return (
              this.totalScore >= item.min_score &&
              this.totalScore < item.max_score
            );
          });
          if (riskLevel.length === 0) {
            const defaultRiskResult = [
              { profile_name: "Unknown", alert_type: "info" }
            ];
            this.riskResult = defaultRiskResult[0];
          } else {
            this.riskResult = riskLevel[0];
          }

          this.$store.commit("changeFirstQuizStatus", true);

          let quizKeyName = this.quiz.id.toString();

          let infoReport = {
            prevCompletedQuiz: {}
          };
          infoReport.prevCompletedQuiz[quizKeyName] = {
            quiz_continue: this.course.id,
            chapter: this.$route.query.chapter,
            page: this.$route.query.page,
            answers: this.answers,
            currentQuestionIndex: this.currentQuestionIndex,
            newAnswers: this.newAnswers,
            quizIntro: this.quizIntro,
            quizId: this.quiz.id,
            quizQuestions: this.quizQuestions,
            questionList: this.questionList,
            totalScore: this.totalScore,
            workerGroupSelected: this.workerGroupSelected,
            firstQuizCompleted: this.firstQuizCompleted,
            scores: this.scores,
            reportId: this.reportId ? this.reportId : this.$route.query.cr,
            quizName: this.quiz.name,
            riskResult: this.riskResult
          };

          const continueReportData = {
            debug_data: infoReport
          };
          ReportService.update({
            id: this.reportId ? this.reportId : this.$route.query.cr,
            data: continueReportData
          });

          this.$store.dispatch("quizStatus/createReport", this.reportId);
        } else {
          this.$store.commit("changeFirstQuizStatus", false);
          // Not the first quiz - get stored report id from vuex
          this.reportId = this.storedReport;
        }

        const riskLevel = this.quiz.risk_profiles?.filter(item => {
          return (
            this.totalScore >= item.min_score &&
            this.totalScore < item.max_score
          );
        });

        if (riskLevel.length === 0) {
          const defaultRiskResult = [
            { profile_name: "Unknown", alert_type: "info" }
          ];
          this.riskResult = defaultRiskResult[0];
        } else {
          this.riskResult = riskLevel[0];
        }
        // ----  check if pass or fail -----
        this.calculateScorePercentage();

        // Store the completed quiz in VueX
        this.$store
          .dispatch("quizStatus/complete", {
            id: this.quiz.id,
            results: this.newAnswers,
            reportId: this.reportId,
            quizName: this.quiz.name,
            riskResult: this.riskResult,
            riskScore: this.totalScore
          })
          .then(
            () => {
              this.quizQuestions = false;
              this.quizResults = true;
            },
            error => {
              console.log(error);
            }
          );

        //POST answers to results endpoint
        const data = {
          report_id: this.reportId,
          quiz_id: this.quiz.id,
          results: this.newAnswers
        };

        await ResultsService.submitResult({
          data: data
        });

        //GET users report from report endpoint

        const usersReportId = this.reportId;

        const reportResponse = await ReportService.getUserReport({
          id: usersReportId
        });

        this.submittedReport = reportResponse.data;
        this.submittedReport.html = "/quizreport/" + this.reportId;

        if (this.finalQuiz) {
          //UPDATE report status
          const reportStatus = {
            status: "completed"
          };

          let reportRes = await ReportService.update({
            id: usersReportId,
            data: reportStatus
          });
          this.finalRiskLevel = reportRes.data.final_risk_level;
        } else {
          //UPDATE report status
          const reportStatus = {
            status: "In Progress"
          };
          await ReportService.update({
            id: usersReportId,
            data: reportStatus
          });
        }

        if (this.finalQuiz) {
          this.$store.dispatch("quizStatus/clearQuiz");
          this.calcFinalRiskAssessment();
          // check if pass or fail
          this.calculateScorePercentage();
        }
      }
      this.loaderQuiz = false;
    },

    //Previous Button
    previousHandler: function() {
      this.currentQuestionIndex--;
      this.answers.pop();
    },
    preventNav(next) {
      if (next.type == "beforeunload") {
        next.preventDefault();
        next.returnValue = "";
        this.nextPage = "refresh";
        this.showModal = true;
      } else {
        this.nextPage = next;
        this.showModal = true;
      }
    },
    gotoDashboard() {
      this.toDashboard = true;
      this.$router.push("/dashboard");
    },
    calculateScorePercentage() {
      this.scorePercentage = (this.totalScore * 100) / this.questionsCount;
      this.scorePercentage =
        Math.round((this.scorePercentage + Number.EPSILON) * 100) / 100;
    },

    async validateReport() {
      const responseReport = await ReportService.getExtendedReport({
        id: this.reportId
      });
      if (
        responseReport.data.report.active_user_id != null &&
        responseReport.data.report.active_user_id != this.currentUser.user.id
      ) {
        this.onLiveEditing = true;
      }
    },

    //Calculate Final risk assessment score
    calcFinalRiskAssessment() {
      if (
        this.$route.query.cr &&
        JSON.parse(this.crData.data.report.debug_data).continueReport &&
        JSON.parse(this.crData.data.report.debug_data).continueReport
          .savedBackup
      ) {
        this.finalRiskAssessmentScore = Object.values(
          JSON.parse(this.crData.data.report.debug_data).continueReport
            .savedBackup
        ).reduce((acc, value) => {
          return acc + value.totalScore;
        }, 0);
        this.finalRiskAssessmentScore += this.totalScore;
      } else {
        this.finalRiskAssessmentScore = this.myQuizzes.reduce((acc, value) => {
          return acc + value.quizRiskTotal;
        }, 0);
      }

      // const riskLevelFinal = this.quiz.risk_levels?.filter(item => {
      //   return (
      //     this.finalRiskAssessmentScore >= item.min_score &&
      //     this.finalRiskAssessmentScore < item.max_score
      //   );
      // });
      // //last fix - risklevel calculation
      // //final step
      // if (riskLevelFinal.length === 0) {
      //   const defaultRiskResult = [
      //     { profile_name: "Unknown", alert_type: "info" }
      //   ];
      //   this.finalRiskAssessment = defaultRiskResult[0];
      // } else {
      //   this.finalRiskAssessment = riskLevelFinal[0];
      // }
    },

    async leaveSite() {
      this.showModal = false;
      localStorage.removeItem("backupQuiz_" + this.quiz.id);
      if (this.nextPage == "refresh") {
        location.reload();
      } else {
        // if (
        //   this.$route.query.cr ||
        //   this.onLiveEditing ||
        //   localStorage.getItem("reportOnCourseID")
        // ) {
        //   await ReportService.update({
        //     id: this.$route.query.cr
        //       ? this.$route.query.cr
        //       : localStorage.getItem("reportOnCourseID"),
        //     data: {
        //       active_user_id: null
        //     }
        //   });
        //   localStorage.removeItem("reportOnCourseID");
        // }
        this.nextPage();
      }
    }
  }
};
</script>
